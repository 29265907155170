export default [
  {
    name: 'companies',
    path: '/companies',
    component: () => import('./Index'),
    meta: {
      showNavbar: true,
      title: 'Empresas',
      icon: 'dvr',
      label: 'Empresas'
    }
  },
  {
    name: 'company-edit',
    path: '/companies/edit/:id',
    component: () => import('./Edit'),
    meta: {
      showNavbar: false,
      title: 'Empresas - Edição',
      icon: 'dvr',
      label: 'Empresas - Edição'
    }
  },
  {
    name: 'company-new',
    path: '/companies/new',
    component: () => import('./New'),
    meta: {
      showNavbar: false,
      title: 'Empresas - Novo Empresa',
      icon: 'dvr',
      label: 'Empresas - Novo Empresa'
    }
  }
]
