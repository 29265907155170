<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveEvent()" autocomplete="off">
          <div class="row">
            <div class="mb-3 col-sm-12">
              <div class="form-group">
                <label for="name">Selecione um evento</label>
                <select name="event" class="form-select" @change="setEvent" v-model="selected">
                  <option v-for="(e,i) in events" :key="i" :value="e">{{ e.name }}</option>
                </select>
                <div
                  v-if="submitted && !$v.event.id.required"
                  class="invalid-feedback"
                >
                  O campo evento é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="start_date">Período de</label>
                <date-picker
                    v-model="event.start_date"
                    disabled
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY HH:mm"
                  >
                  </date-picker>
              </div>
            </div>
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="end_date">Período até</label>
                <date-picker
                    v-model="event.end_date"
                    disabled
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY HH:mm"
                  >
                  </date-picker>
              </div>
            </div>
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="event_price">Valor</label>
                <input
                  id="event_price"
                  v-model="event.event_price"
                  name="event_price"
                  type="text"
                  class="form-control text-end"
                  :class="{
                    'is-invalid': submitted && $v.event.event_price.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.event.event_price.required"
                  class="invalid-feedback"
                >
                  O campo valor é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveEvent()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Loading,
    DatePicker
  },
  props: {
  },
  data () {
    return {
      title: 'Adicionar Evento - Fatura',
      loading: true,
      selected: {},
      fullPage: true,
      submitted: false,
      states: [],
      event: {},
      company: '',
      modal: false
    }
  },
  validations: {
    event: {
      id: { required },
      event_price: { required }
    }
  },
  methods: {
    ...mapActions('events', {
      getEvents: 'getAll'
    }),
    getEventsByCompany (company) {
      let params = {
        include: 'company,plan',
        limit: 100,
        page: 1,
        company: company
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getEvents(this.params).then((res) => {
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    setEvent () {
      this.event = this.selected
      this.event.event_price = this.selected.plan.data.plan_value
      this.event.start_date = this.$moment(this.event.start_date, 'YYYY-MM-DD HH:mm').toDate()
      this.event.end_date = this.$moment(this.event.end_date, 'YYYY-MM-DD HH:mm').toDate()
    },
    open (company) {
      this.modal = true
      this.loading = true
      this.getEventsByCompany(company)
      this.loading = false
    },
    saveEvent () {
      this.submitted = true
      this.$v.event.$touch()
      if (this.$v.event.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      this.event.event_price = parseFloat(this.event.event_price)
      this.$emit('created', this.event)
      this.close()
    },
    close () {
      this.modal = false
      this.event = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('events', ['events', 'pagination'])
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
