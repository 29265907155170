export default [
  {
    name: 'events',
    path: '/events',
    component: () => import('./Index'),
    meta: {
      showNavbar: true,
      title: 'Meus Eventos',
      icon: 'dvr',
      label: 'Meus Eventos'
    }
  },
  {
    name: 'event-edit',
    path: '/events/edit/:id',
    component: () => import('./Edit'),
    meta: {
      showNavbar: false,
      title: 'Meus Eventos - Edição',
      icon: 'dvr',
      label: 'Meus Eventos - Edição'
    }
  }
]
