<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <NavBar />
      <SideBar :is-condensed="isMenuCondensed" type="dark" width="fluid" />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
            <!-- <Drawer
              @close="rightDrawer"
              :align="'right'"
              :closeable="true"
              :maskClosable="true"
              :zIndex="1002"
            >
              <div v-if="openRightDrawer">
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="offcanvasExampleLabel">
                    Notificação
                  </h5>
                </div>
                <div class="offcanvas-body">
                  <div>
                    Some text as placeholder. In real life you can have the
                    <br />elements you have chosen. Like, text, images, lists, etc.
                  </div>
                </div>
              </div>
            </Drawer> -->
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import NavBar from '@/components/nav-bar'
import SideBar from '@/components/side-bar'
import Footer from '@/components/footer'
// import { mapState } from 'vuex'

/**
 * Vertical layout
 */
export default {
  components: { NavBar, SideBar, Footer },
  data () {
    return {
      isMenuCondensed: false,
      openRightDrawer: true
    }
  },
  computed: {
    // ...mapState('auth', ['user'])
  },
  created: () => {
    document.body.removeAttribute('data-layout', 'horizontal')
    // document.body.removeAttribute('data-topbar', 'dark')
    document.body.setAttribute('data-topbar', 'dark')
    document.body.removeAttribute('data-layout-size', 'boxed')
    document.body.classList.remove('auth-body-bg')
  },
  methods: {
    rightDrawer () {
      this.openRightDrawer = !this.openRightDrawer
    },
    toggleMenu () {
      document.body.classList.toggle('sidebar-enable')

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
          document.body.classList.remove('vertical-collpsed')
        })
        document.body.classList.toggle('vertical-collpsed')
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
        })
        document.body.classList.remove('vertical-collpsed')
      }
      this.isMenuCondensed = !this.isMenuCondensed
    },
    toggleRightSidebar () {
      document.body.classList.toggle('right-bar-enabled')
    },
    hideRightSidebar () {
      document.body.classList.remove('right-bar-enabled')
    }
    // connectSocket () {
    //   console.log(this.user)
    //   this.$pusher.subscribe(`event-steps.${this.user.company_id}`).bind('EventSteps', (response) => {
    //     console.log(response)
    //     if (response) {
    //       this.event_step.percent = response.percent
    //       this.event_step.message = response.message
    //     }
    //   })
    // },
    // accessEvent (url) {
    //   window.open(url, '_blank')
    // }
  },
  mounted () {
    if (this.loader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'

      setTimeout(function () {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 2500)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }
  },
  beforeMount () {
    // this.connectSocket()
  }
}
</script>
<style>
.vue-simple-drawer {
  background: #fff;
  color: #333;
}
</style>
