export default [
  {
    name: 'invoices',
    path: '/invoices',
    component: () => import('./Index'),
    meta: {
      showNavbar: true,
      title: 'Minhas Faturas',
      icon: 'dvr',
      label: 'Minhas Faturas'
    }
  },
  {
    name: 'invoice-show',
    path: '/invoices/show/:id',
    component: () => import('./Show'),
    meta: {
      showNavbar: false,
      title: 'Minhas Faturas - Detalhes',
      icon: 'dvr',
      label: 'Minhas Faturas - Detalhes'
    }
  },
  {
    name: 'invoice-new',
    path: '/invoices/new',
    component: () => import('./New'),
    meta: {
      showNavbar: false,
      title: 'Nova Fatura',
      icon: 'dvr',
      label: 'Nova Fatura'
    }
  },
  {
    name: 'invoice-edit',
    path: '/invoices/edit/:id',
    component: () => import('./Edit'),
    meta: {
      showNavbar: false,
      title: 'Faturas - Editar',
      icon: 'dvr',
      label: 'Faturas - Editar'
    }
  }
]
