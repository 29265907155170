var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Dados da fatura")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Título da Fatura ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoice.name),expression:"invoice.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.invoice.name.$error,
                  },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.invoice.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.invoice, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.invoice.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo título é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"company"}},[_vm._v(" Empresa ")]),_c('multiselect',{staticClass:"helo",attrs:{"tag-placeholder":"Selecione uma empresa","placeholder":"Selecione uma empresa","options":_vm.optionsCompanies,"selectLabel":"Click para selecionar","selectedLabel":"Selecionado","deselectLabel":"Click para remover","label":"name","track-by":"id","max-height":180,"open-direction":"bottom"},model:{value:(_vm.invoice.company),callback:function ($$v) {_vm.$set(_vm.invoice, "company", $$v)},expression:"invoice.company"}}),(_vm.submitted && !_vm.$v.invoice.company.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo empresa é obrigatório ")]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"invoice_date"}},[_vm._v(" Data da Fatura ")]),_c('date-picker',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.invoice.invoice_date.$error,
                  },attrs:{"type":"date","first-day-of-week":1,"lang":"pt-br","format":"DD/MM/YYYY"},model:{value:(_vm.invoice.invoice_date),callback:function ($$v) {_vm.$set(_vm.invoice, "invoice_date", $$v)},expression:"invoice.invoice_date"}}),(_vm.submitted && !_vm.$v.invoice.invoice_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo data fatura é obrigatório ")]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"invoice_due_date"}},[_vm._v(" Data de Vencimento ")]),_c('date-picker',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.invoice.invoice_due_date.$error,
                  },attrs:{"type":"date","first-day-of-week":1,"lang":"pt-br","format":"DD/MM/YYYY"},model:{value:(_vm.invoice.invoice_due_date),callback:function ($$v) {_vm.$set(_vm.invoice, "invoice_due_date", $$v)},expression:"invoice.invoice_due_date"}}),(_vm.submitted && !_vm.$v.invoice.invoice_due_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo data de vencimento é obrigatório ")]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"obs"}},[_vm._v(" Descrição da Fatura ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.invoice.description),expression:"invoice.description"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.invoice.invoice_due_date.$error,
                  },attrs:{"id":"obs","name":"obs","type":"text","rows":"4"},domProps:{"value":(_vm.invoice.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.invoice, "description", $event.target.value)}}}),(_vm.submitted && !_vm.$v.invoice.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo descrição é obrigatório ")]):_vm._e()])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4 float-sm-start"},[_vm._v("Eventos")]),_c('div',{staticClass:"float-sm-end d-flex"},[_c('div',{staticClass:"text-sm-left me-2"},[_c('button',{staticClass:"btn btn-success btn-sm btn-rounded mb-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.addEvent()}}},[_c('i',{staticClass:"mdi mdi-plus mr-1"}),_vm._v(" Adicionar Evento ")])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-centered table-nowrap table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Evento")]),_c('th',[_vm._v("Capacidade")]),_c('th',[_vm._v("Período")]),_c('th',[_vm._v("Valor")]),_c('th',[_vm._v("Ações")])])]),_c('tbody',[_vm._l((_vm.eventsNew),function(list,i){return _c('tr',{key:i},[_c('td',[_c('span',{staticClass:"badge badge-pill font-size-12",class:{
                      'badge-soft-dark': ("" + (parseInt(list.status))) === '0',
                      'badge-soft-success': ("" + (parseInt(list.status))) === '1',
                      'badge-soft-warning': ("" + (parseInt(list.status))) === '2',
                      'badge-soft-warning': ("" + (parseInt(list.status))) === '3',
                      'badge-soft-danger': ("" + (parseInt(list.status))) === '4',
                    }},[_vm._v(_vm._s(_vm.statusLabel[list.status]))])]),_c('td',[_vm._v(_vm._s(list.name))]),_c('td',[_vm._v(_vm._s(list.plan.data.name))]),_c('td',[_vm._v(_vm._s(_vm.$moment(list.start_date).format('DD/MM HH:mm'))+" - "+_vm._s(_vm.$moment(list.end_date).format('DD/MM HH:mm')))]),_c('td',{staticClass:"text-sm-end"},[_vm._v(_vm._s(list.event_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })))]),_c('td',[_c('b-dropdown',{staticClass:"card-drop",attrs:{"variant":"white","right":"","toggle-class":"p-0","menu-class":"dropdown-menu-end"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"mdi mdi-dots-horizontal font-size-18"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.delEvent(i)}}},[_c('i',{staticClass:"fas fa-trash-alt text-danger mr-1"}),_vm._v(" Remover ")])],1)],1)])}),(_vm.events.length > 0)?_c('tr',{staticClass:"mt-2"},[_c('td',{staticClass:"border-0 text-sm-end",attrs:{"colspan":"4"}},[_c('strong',[_vm._v("Total Fatura")])]),_c('td',{staticClass:"border-0 text-sm-end"},[_c('h5',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.totalEvents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })))])]),_c('td',{staticClass:"border-0"})]):_vm._e()],2)])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"float-end"},[_c('button',{staticClass:"btn btn-dark me-1",attrs:{"type":"buton"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"btn btn-primary me-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveInvoice()}}},[_vm._v(" Salvar ")])])])])])]),_c('modal-form',{ref:"formEvent",on:{"created":_vm.addEventInvoice},model:{value:(_vm.modalForm),callback:function ($$v) {_vm.modalForm=$$v},expression:"modalForm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }