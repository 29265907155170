import { store as auth } from '@/modules/auth'
import { store as companies } from '@/pages/companies'
import { store as events } from '@/pages/events'
import { store as invoices } from '@/pages/invoices'
import { store as users } from '@/pages/users'
import { store as plans } from '@/pages/plans'
import { store as pushers } from '@/pages/pushers'

export default {
  auth,
  companies,
  events,
  invoices,
  users,
  plans,
  pushers
}
