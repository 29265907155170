<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Dados da empresa</h4>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group mb-3">
                  <label for="fiscal_name">
                    Razão Social
                  </label>
                  <input
                    id="fiscal_name"
                    v-model="company.fiscal_name"
                    name="fiscal_name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.fiscal_name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.fiscal_name.required"
                    class="invalid-feedback"
                  >
                    O campo razão social é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group mb-3">
                  <label for="name">
                    Nome Fantasia
                  </label>
                  <input
                    id="name"
                    v-model="company.name"
                    name="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.name.required"
                    class="invalid-feedback"
                  >
                    O campo nome fantasia é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group mb-3">
                  <label for="cnpj">
                    CNPJ
                  </label>
                  <input
                    id="cnpj"
                    v-model="company.cnpj"
                    name="cnpj"
                    type="text"
                    v-mask="'##.###.###/####-##'"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.cnpj.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.cnpj.required"
                    class="invalid-feedback"
                  >
                  O campo CNPJ é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group mb-3">
                  <label for="phone">
                    Telefone
                  </label>
                  <input
                    id="phone"
                    v-model="company.phone"
                    name="phone"
                    v-mask="'(##) #####-####'"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.phone.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.phone.required"
                    class="invalid-feedback"
                  >
                  O campo telefone é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group mb-3">
                  <label for="email">
                    E-mail Contato
                  </label>
                  <input
                    id="email"
                    v-model="company.email"
                    name="email"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.email.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.email.required"
                    class="invalid-feedback"
                  >
                  O campo e-mail de contato é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <label for="obs">
                    Observações
                  </label>
                  <textarea
                    id="obs"
                    v-model="company.obs"
                    name="obs"
                    type="text"
                    rows="4"
                    class="form-control"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-2">Dados do administrador</h4>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-centered">
                  <thead>
                    <tr>
                      <th width="60%">Nome</th>
                      <th width="30%">E-mail</th>
                      <th width="10%">Senha</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(usr, i) in company.user" :key="i">
                      <td>{{ usr.name }}</td>
                      <td>{{ usr.email }}</td>
                      <td><button class="btn btn-dark btn-sm" @click="showModal(usr)">Resetar</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-2">Dados do domínio</h4>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-centered">
                  <thead>
                    <tr>
                      <th width="100%">Domínio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, i) in company.domains" :key="i">
                      <td>{{ d.domain }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <button type="buton" @click="cancelar()" class="btn btn-dark me-1">
                Cancelar
              </button>
              <button type="button" @click="updateData()" class="btn btn-primary me-1">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-center"
        ref="modal-password"
        title="Resetar senha"
        title-class="font-18"
        size="sm"
        :no-close-on-backdrop="true"
      >
        <form @submit.prevent="resetPassword()">
          <div class="row mt-2">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="password">Nova senha</label>
                <input
                  v-model="user.password"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.user.password.$error,
                  }"
                />
                <div
                  v-if="submitted && !$v.user.password.required"
                  class="invalid-feedback"
                >
                O campo senha é obrigatório
                </div>
              </div>
            </div>
          </div>
        </form>
        <template v-slot:modal-footer>
          <b-button variant="secondary" @click="closeModal()">Cancelar</b-button>
          <b-button variant="primary" @click="resetPassword()">Salvar</b-button>
        </template>
      </b-modal>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'form-company-edit',
  page: {
    title: 'Editar informações',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      plans: [],
      user: {},
      company: {
        id: '',
        name: '',
        status: 0,
        description: '',
        user: {
          password: '',
          name: '',
          email: ''
        }
      },
      submitted: false,
      title: 'Editar Empresa',
      items: [{
        text: 'Empresas',
        href: '/companies'
      },
      {
        text: 'Editar Empresa',
        active: true
      }
      ]
    }
  },
  validations: {
    company: {
      name: { required },
      fiscal_name: { required },
      phone: { required },
      cnpj: { required },
      email: { required },
      user: {
        name: { required },
        email: { required },
        password: { required }
      }
    },
    user: {
      password: { required }
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('companies', { data: 'company' }),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('companies', {
      fetch: 'getCompany',
      postData: 'save',
      newPassword: 'savePassword'
    }),
    closeModal () {
      this.$refs['modal-password'].hide()
      this.user = {}
      this.$v.user.$reset()
    },
    showModal (user) {
      this.$refs['modal-password'].show()
      this.user = user
    },
    async resetPassword () {
      this.submitted = true
      this.$v.user.$touch()
      if (this.$v.user.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()

      await this.newPassword(this.user).then((data) => {
        let message = 'Sucesso: Ação registrada.'
        let delay = 2500
        successMessage(message, delay).then(() => {
          loader.hide()
          this.cancelar()
        })
      }).catch((err) => {
        console.log(err)
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        loader.hide()
      })
    },
    cancelar () {
      this.$router.push({ name: 'companies' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.fetch(this.$route.params.id)
          .then(() => {
            this.company = this.data
            if (this.data.users) {
              this.company.user = this.data.users.data
            }
            if (this.data.domains) {
              this.company.domains = this.data.domains.data
            }
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    async updateData () {
      this.submitted = true
      this.$v.company.$touch()
      if (this.$v.company.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()

      await this.postData(this.company).then((data) => {
        let message = 'Sucesso: Ação registrada.'
        let delay = 2500
        successMessage(message, delay).then(() => {
          loader.hide()
          this.cancelar()
        })
      }).catch((err) => {
        console.log(err)
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        loader.hide()
      })
    }
  },
  watch: {}
}
</script>
