<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row mb-2">
          <div class="col-sm-5">
            <div class="search-box mr-2 mb-2">
              <div class="position-relative">
                <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                <i class="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="float-sm-end d-flex">
              <div class="text-sm-left me-2">
                <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newCompany()">
                  <i class="mdi mdi-plus mr-1"></i> Criar Empresa
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table project-list-table table-nowrap align-middle table-centered">
            <thead>
              <tr>
                <th scope="col" width="10%">Status</th>
                <th scope="col" width="40%">Empresa</th>
                <th scope="col" width="15%">CNPJ</th>
                <th scope="col" width="20%">Contato</th>
                <th scope="col" width="10%">Eventos</th>
                <th scope="col" width="">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="companies.length === 0">
                <td colspan="5" class="text-center p-5">
                  Nenhuma empresa encontrada.
                </td>
              </tr>
              <tr v-for="(company, i) in companies" :key="i" class="link">
                <td @click="showComapny(company.id)">
                  <span class="badge badge-pill font-size-12" :class="{
                      'badge-soft-danger': `${parseInt(company.status)}` === '0',
                      'badge-soft-success': `${parseInt(company.status)}` === '1',
                      'badge-soft-dark': `${parseInt(company.status)}` === '2',
                    }">{{ statusLabel[company.status] }}</span>
                </td>
                <td @click="showComapny(company.id)">
                  <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ company.name }}</a> <br />
                      <span class="font-size-12"> {{ company.fiscal_name }} </span>
                  </h5>
                </td>
                <td @click="showComapny(company.id)">
                  <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ company.cnpj }}</a>
                  </h5>
                </td>
                <td @click="showComapny(company.id)">{{ company.email }} <br /> {{ company.phone }}</td>
                <td @click="showComapny(company.id)">{{ company.events + ' evento(s)' }}</td>
                <td>
                  <b-dropdown class="card-drop" variant="white" right toggle-class="p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <b-dropdown-item @click="toggleStatus(company.id)">
                      <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(company.status) === 1, 'text-success': parseInt(company.status) === 0 }"></i> {{ company.status ? 'Inativar' : 'Ativar' }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="showComapny(company.id)">
                      <i class="fas fa-pencil-alt text-primary mr-1"></i> Editar
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="companies.length > 0">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { mapActions, mapState } from 'vuex'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'companies',
  page: {
    title: 'Empresas',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      submitted: false,
      search: '',
      params: {},
      plans: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo', 'Inadimplente'],
      title: 'Empresas',
      items: [{
        text: 'Empresas',
        href: '/events'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('companies', ['companies', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('companies', {
      getAll: 'getAll',
      changeStatus: 'changeStatus'
    }),
    showComapny (id) {
      this.$router.push({
        name: 'company-edit',
        params: {
          id: id
        }
      })
    },
    newCompany () {
      this.$router.push({
        name: 'company-new'
      })
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        plan: filter.plan || null,
        limit: this.perPage,
        page: this.currentPage,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
        console.log(this.companies)
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>

<style scoped>
.table-responsive { min-height: 600px;}
</style>
