var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Dados da empresa")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"fiscal_name"}},[_vm._v(" Razão Social ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.fiscal_name),expression:"company.fiscal_name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.fiscal_name.$error,
                  },attrs:{"id":"fiscal_name","name":"fiscal_name","type":"text"},domProps:{"value":(_vm.company.fiscal_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "fiscal_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.company.fiscal_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo razão social é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Nome Fantasia ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.name),expression:"company.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.name.$error,
                  },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.company.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.company.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo nome fantasia é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"cnpj"}},[_vm._v(" CNPJ ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.cnpj),expression:"company.cnpj"},{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.cnpj.$error,
                  },attrs:{"id":"cnpj","name":"cnpj","type":"text"},domProps:{"value":(_vm.company.cnpj)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "cnpj", $event.target.value)}}}),(_vm.submitted && !_vm.$v.company.cnpj.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo CNPJ é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"phone"}},[_vm._v(" Telefone ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.phone),expression:"company.phone"},{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.phone.$error,
                  },attrs:{"id":"phone","name":"phone","type":"text"},domProps:{"value":(_vm.company.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "phone", $event.target.value)}}}),(_vm.submitted && !_vm.$v.company.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo telefone é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v(" E-mail Contato ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.email),expression:"company.email"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.email.$error,
                  },attrs:{"id":"email","name":"email","type":"text"},domProps:{"value":(_vm.company.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "email", $event.target.value)}}}),(_vm.submitted && !_vm.$v.company.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo e-mail de contato é obrigatório ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"obs"}},[_vm._v(" Observações ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.obs),expression:"company.obs"}],staticClass:"form-control",attrs:{"id":"obs","name":"obs","type":"text","rows":"4"},domProps:{"value":(_vm.company.obs)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "obs", $event.target.value)}}})])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Dados do administrador")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"username"}},[_vm._v(" Nome do usuário ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.user.name),expression:"company.user.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.user.name.$error,
                  },attrs:{"id":"username","name":"username","type":"text"},domProps:{"value":(_vm.company.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company.user, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.company.user.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo nome é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"useremail"}},[_vm._v(" E-mail ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.user.email),expression:"company.user.email"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.user.email.$error,
                  },attrs:{"id":"useremail","name":"useremail","type":"text"},domProps:{"value":(_vm.company.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company.user, "email", $event.target.value)}}}),(_vm.submitted && !_vm.$v.company.user.email.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo e-mail é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"senha"}},[_vm._v(" Senha ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.user.password),expression:"company.user.password"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.user.password.$error,
                  },attrs:{"id":"senha","name":"senha","type":"password"},domProps:{"value":(_vm.company.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company.user, "password", $event.target.value)}}}),(_vm.submitted && !_vm.$v.company.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo senha é obrigatório ")]):_vm._e()])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Dados do domínio")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"domain_id"}},[_vm._v(" Escolha um domínio ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.domain_id),expression:"company.domain_id"}],staticClass:"form-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.company.domain_id.$error,
                  },attrs:{"name":"domain_id","id":"domain_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.company, "domain_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.domains),function(domain,i){return _c('option',{key:i,domProps:{"value":domain.id}},[_vm._v(_vm._s(domain.domain))])}),0),(_vm.submitted && !_vm.$v.company.domain_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"float-end"},[_c('button',{staticClass:"btn btn-dark me-1",attrs:{"type":"buton"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"btn btn-primary me-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveCompany()}}},[_vm._v(" Salvar ")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }