import http from '@/http'
const URL = ''

export const login = (payload) => http.post(URL + '/admin/auth/login', payload)
export const logout = (payload) => http.post(URL + '/admin/auth/logout', payload)
export const loadSession = () => http.get(URL + '/admin/me')

export default {
  login,
  logout,
  loadSession
}
