<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="savePusher()" autocomplete="off">
          <div class="row">
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="name">ID</label>
                <input
                  id="name"
                  v-model="pusher.pusher_id"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.pusher.pusher_id.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.pusher.pusher_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="description">KEY</label>
                <input
                  id="description"
                  v-model="pusher.pusher_key"
                  name="description"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.pusher.pusher_key.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.pusher.pusher_key.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="max_online">Secret</label>
                <input
                  id="max_registers"
                  v-model="pusher.pusher_secret"
                  name="max_registers"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.pusher.pusher_secret.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.pusher.pusher_secret.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="mb-3 col-sm-2">
              <div class="form-group">
                <label for="max_online">Cluster</label>
                <input
                  id="max_online"
                  v-model="pusher.pusher_cluster"
                  name="pusher_cluster"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.pusher.pusher_cluster.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.pusher.pusher_cluster.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
            <div class="mb-3 col-sm-2">
              <div class="form-group">
                <label for="event_id">Evento ID</label>
                <input
                  id="event_id"
                  v-model="pusher.event_id"
                  name="event_id"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="mb-3 col-sm-3">
              <div class="form-group">
                <label for="available">Disponibilidade</label>
                <select
                  id="available"
                  v-model="pusher.available"
                  name="available"
                  class="form-select"
                  :class="{
                    'is-invalid': submitted && $v.pusher.available.$error
                  }"
                >
                  <option value="1">SIM</option>
                  <option value="0">NÃO</option>
                </select>
                <div
                  v-if="submitted && !$v.pusher.available.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="savePusher()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idpusher: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Pusher',
      loading: true,
      fullPage: true,
      submitted: false,
      states: [],
      pusher: {},
      modal: false
    }
  },
  validations: {
    pusher: {
      pusher_id: { required },
      pusher_key: { required },
      pusher_secret: { required },
      pusher_cluster: { required },
      available: { required }
    }
  },
  methods: {
    ...mapActions('pushers', {
      getpusher: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Pusher'
        this.getpusher(id).then((data) => {
          this.pusher = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async savePusher () {
      this.submitted = true
      this.$v.pusher.$touch()
      if (this.$v.pusher.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.pusher).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.pusher = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('pushers', { data: 'pusher' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
