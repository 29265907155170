<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row mb-2">
          <div class="col-sm-6">
            <div class="search-box mr-2 mb-2  text-sm-right">
              <div class="position-relative">
                <input type="text" class="form-control"  v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                <i class="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="float-sm-end d-flex">
              <div class="text-sm-left me-2">
                <button type="button" class="btn btn-success btn-rounded mb-2" @click="newInvoice()">
                  <i class="mdi mdi-plus mr-1"></i> Criar Fatura
                </button>
              </div>
            </div>
          </div>
          <!-- end col-->
        </div>
        <div class="table-responsive">
          <table class="table project-list-table table-nowrap table-centered table-borderless">
            <thead>
              <tr>
                <th scope="col" width="12%">Status</th>
                <th scope="col" width="25%">Empresa</th>
                <th scope="col" width="20%">Fatura</th>
                <th scope="col" width="10%">Eventos</th>
                <th scope="col" width="10%">Faturado</th>
                <th scope="col" width="12%">Vencimento</th>
                <th scope="col" width="15%">Valor</th>
                <th scope="col">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="invoices.length === 0">
                <td colspan="7" class="text-center p-5">
                  Nenhuma fatura encontrada.
                </td>
              </tr>
              <tr v-for="(invoice, i) in invoices" :key="i" class="link">
                <td>
                  <b-dropdown
                    size="sm"
                    :variant="(parseInt(invoice.status)) === 3 ? 'danger' : parseInt(invoice.status) === 1 ? 'warning' : 'success'"
                    class="m-0 rounded-drop">
                    <template v-slot:button-content >
                      {{ statusLabel[invoice.status] }}
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <span>
                      <b-dropdown-item-button @click="toggleStatus(invoice.id, 1)" v-if="parseInt(invoice.status) !== 1">Pagamento Pendente</b-dropdown-item-button>
                      <b-dropdown-item-button @click="toggleStatus(invoice.id, 2)" v-if="parseInt(invoice.status) !== 2">Pago</b-dropdown-item-button>
                      <b-dropdown-item-button @click="toggleStatus(invoice.id, 3)" v-if="parseInt(invoice.status) !== 3">Cancelada</b-dropdown-item-button>
                    </span>
                  </b-dropdown>
                </td>
                <td @click="showInvoice(invoice.id)">
                  <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ invoice.company.data.name }}</a> <br />
                      <span class="font-size-12"> {{ invoice.company.data.fiscal_name }} </span>
                  </h5>
                </td>
                <td @click="showInvoice(invoice.id)">
                  <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ invoice.name }}</a>
                  </h5>
                </td>
                <td @click="showInvoice(invoice.id)">{{ invoice.qty_events }} Evento(s)</td>
                <td @click="showInvoice(invoice.id)">{{ $moment(invoice.invoice_date).format('DD/MM/YYYY') }}</td>
                <td @click="showInvoice(invoice.id)">{{ $moment(invoice.invoice_due_date).format('DD/MM/YYYY') }}</td>
                <td @click="showInvoice(invoice.id)">{{ invoice.invoice_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
                <td>
                  <b-dropdown class="card-drop" variant="white" right toggle-class="p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <b-dropdown-item @click="editInvoice(invoice.id)">
                      <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="showInvoice(invoice.id)">
                      <i class="fas fa-print text-dark mr-1"></i> Imprimir
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="invoices.length > 0">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'invoices',
  page: {
    title: 'Faturas',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      submitted: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['', 'Pagamento Pendente', 'Pago', 'Cancelada'],
      title: 'Faturas',
      items: [{
        text: 'Faturas',
        href: '/invoices'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('invoices', ['invoices', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('invoices', {
      getAll: 'getAll',
      changeStatus: 'changeStatus'
    }),
    newInvoice () {
      this.$router.push({
        name: 'invoice-new'
      })
    },
    editInvoice (id) {
      this.$router.push({
        name: 'invoice-edit',
        params: {
          id: id
        }
      })
    },
    showInvoice (id) {
      this.$router.push({
        name: 'invoice-show',
        params: {
          id: id
        }
      })
    },
    async toggleStatus (id, status) {
      try {
        await this.changeStatus({ id: id, status: status })
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    getReport (filter) {
      let params = {
        include: 'company,items',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>

<style scoped>
.table-responsive { min-height: 600px;}
</style>
