<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row mb-2">
          <div class="col-sm-5">
            <div class="search-box mr-2 mb-2">
              <div class="position-relative">
                <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                <i class="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="float-sm-end d-flex">
              <div class="text-sm-right">
                  <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                    <i class="mdi mdi-filter mr-1"></i> Filtros
                  </button>
                </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table project-list-table table-nowrap align-middle table-centered">
            <thead>
              <tr>
                <th scope="col" width="15%">Status</th>
                <th scope="col" width="20%">Empresa</th>
                <th scope="col" width="40%">Evento</th>
                <th scope="col" width="10%">Capacidade</th>
                <th scope="col" width="12%">Período</th>
                <th scope="col" width="12%">Valor</th>
                <th scope="col" width="">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="events.length === 0">
                <td colspan="8" class="text-center p-5">
                  Nenhum evento encontrado.
                </td>
              </tr>
              <tr v-for="(event, i) in events" :key="i" class="link">
                <td @click="showEvent(event.id)">
                  <span class="badge badge-pill font-size-12" :class="{
                      'badge-soft-dark': `${parseInt(event.status)}` === '0',
                      'badge-soft-success': `${parseInt(event.status)}` === '1',
                      'badge-soft-warning': `${parseInt(event.status)}` === '2',
                      'badge-soft-warning': `${parseInt(event.status)}` === '3',
                      'badge-soft-danger': `${parseInt(event.status)}` === '4',
                    }">{{ statusLabel[event.status] }}</span>
                </td>
                <td @click="showEvent(event.id)">
                  <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ event.company.data.name }}</a> <br />
                      <span class="font-size-12"> {{ event.company.data.fiscal_name }} </span>
                  </h5>
                </td>
                <td @click="showEvent(event.id)">
                  <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ event.name }}</a>
                  </h5>
                </td>
                <td>{{ event.plan.data.name || '' }}</td>
                <td @click="showEvent(event.id)">{{ $moment(event.start_date).format('DD/MM HH:mm') }} - {{ $moment(event.end_date).format('DD/MM HH:mm') }}</td>
                <td>{{ event.plan.data.plan_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
                <td>
                  <b-dropdown class="card-drop" variant="white" right toggle-class="p-0" menu-class="dropdown-menu-end">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <b-dropdown-item @click="accessEvent(event.url_admin)" v-if="event.status === 1">
                      <i class="dripicons-gear text-primary mr-1"></i> Gerênciar Evento
                    </b-dropdown-item>
                    <b-dropdown-item @click="accessEvent(event.url_event)" v-if="event.status === 1">
                      <i class="bx bx-navigation text-primary mr-1"></i> Plataforma Evento
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="events.length > 0">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <modal-filter ref="modalFilter" @filtered="getReport"></modal-filter>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import modalFilter from '@/components/modals/filters/EventFilter'
import appConfig from '@/app.config'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    modalFilter
  },
  name: 'events',
  page: {
    title: 'Meus Eventos',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      submitted: false,
      search: '',
      params: {},
      plans: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['Cancelado', 'Publicado', 'Em Produção', 'Em Produção - Otimizando', 'Encerrado'],
      title: 'Meus Eventos',
      items: [{
        text: 'Meus Eventos',
        href: '/events'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('events', ['events', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('events', {
      getAll: 'getAll'
    }),
    showEvent (id) {
      this.$router.push({
        name: 'event-edit',
        params: {
          id: id
        }
      })
    },
    waitingEvent (id) {
      this.$router.push({
        name: 'event-in-production',
        params: {
          id: id
        }
      })
    },
    accessEvent (url) {
      window.open(url, '_blank')
    },
    newEvent () {
      this.$router.push({
        name: 'event-new'
      })
    },
    showFilters () {
      this.$refs.modalFilter.open()
    },
    getReport (filter) {
      let params = {
        include: 'company,operator,plan',
        search: this.search || null,
        plan: filter.plan || null,
        limit: this.perPage,
        page: this.currentPage,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>

<style scoped>
.table-responsive { min-height: 600px;}
</style>
