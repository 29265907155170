<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="savePlan()" autocomplete="off">
          <div class="row">
            <div class="mb-3 col-sm-3">
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  id="name"
                  v-model="plan.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.plan.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.plan.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
            <div class="mb-3 col-sm-9">
              <div class="form-group">
                <label for="description">Descrição</label>
                <input
                  id="description"
                  v-model="plan.description"
                  name="description"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.plan.description.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.plan.description.required"
                  class="invalid-feedback"
                >
                  O campo descrição é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="max_online">Max Registros</label>
                <input
                  id="max_registers"
                  v-model="plan.max_registers"
                  name="max_registers"
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.plan.max_registers.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.plan.max_registers.required"
                  class="invalid-feedback"
                >
                  O campo max registros é obrigatório.
                </div>
              </div>
            </div>
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="max_online">Max Online</label>
                <input
                  id="max_online"
                  v-model="plan.max_online"
                  name="max_online"
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.plan.max_online.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.plan.max_online.required"
                  class="invalid-feedback"
                >
                  O campo max online é obrigatório.
                </div>
              </div>
            </div>
            <div class="mb-3 col-sm-4">
              <div class="form-group">
                <label for="plan_value">Valor</label>
                <input
                  v-money="money"
                  id="plan_value"
                  v-model="plan.plan_value"
                  name="plan_value"
                  type="text"
                  class="form-control text-end"
                  :class="{
                    'is-invalid': submitted && $v.plan.plan_value.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.plan.plan_value.required"
                  class="invalid-feedback"
                >
                  O campo valor é obrigatório.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="savePlan()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idplan: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Plano',
      loading: true,
      roomSelected: {},
      fullPage: true,
      submitted: false,
      states: [],
      plan: {},
      modal: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    }
  },
  validations: {
    plan: {
      name: { required },
      description: { required },
      max_registers: { required },
      max_online: { required },
      plan_value: { required }
    }
  },
  methods: {
    ...mapActions('plans', {
      getPlan: 'show',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Plano'
        this.getPlan(id).then((data) => {
          this.plan = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async savePlan () {
      this.submitted = true
      this.$v.plan.$touch()
      if (this.$v.plan.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      this.plan.plan_value = parseFloat(this.plan.plan_value)
      await this.postData(this.plan).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.plan = {}
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('plans', { data: 'plan' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
