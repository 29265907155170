<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newUser()">
                      <i class="mdi mdi-plus mr-1"></i> Novo Usuário
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th style="width: 70px;"></th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in admin_users" :key="list.id">
                    <td>
                      <div>
                        <img class="rounded avatar-xs" :src="(list.avatar && list.avatar !== 'undefined') ? list.avatar : avatar" alt />
                      </div>
                    </td>
                    <td>{{ list.name }}</td>
                    <td>{{ list.email }}</td>
                    <td>
                      <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(list.status)}` === '1',
                        'badge-soft-danger': `${parseInt(list.status)}` === '0',
                      }">{{ statusLabel[list.status] }}</span>
                    </td>
                    <td>
                      <b-dropdown class="card-drop" variant="white" right toggle-class="p-0" menu-class="dropdown-menu-end">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editUser(list.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(list.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(list.status) === 1, 'text-success': parseInt(list.status) === 0 }"></i> {{ list.status ? 'Inativar' : 'Ativar' }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idUser="idUser" ref="formUser" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import Swal from 'sweetalert2'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm
  },
  name: 'admin-users',
  page: {
    title: 'Administração - Usuários',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      avatar: require('@/assets/images/users/user-default.png'),
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      modalDetail: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idUser: null,
      perPage: 20,
      statusLabel: ['Inativo', 'Ativo'],
      submitted: false,
      title: 'Administração - Usuários',
      items: [{
        text: 'Administração - Usuários'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('users', ['admin_users', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('users', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      deleteParticipant: 'deleteUser'
    }),
    newUser () {
      this.$refs.formUser.open('')
      this.modalForm = true
    },
    editUser (id) {
      this.$refs.formUser.open(id)
      this.modalForm = true
      this.idUser = id
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteUser (id) {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Essa ação não poderá ser revertida',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.deleteParticipant(id).then((res) => {
              successMessage('Sucesso: Usuário excluído com sucesso.', 2500)
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        include: 'roles.permissions',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.admin_users = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
