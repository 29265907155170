<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="plan">Plano</label>
                <select
                  class="form-select"
                  name="plan"
                  v-model="filter.plan"
                >
                  <option value="">Todos</option>
                  <option v-for="(p, i) in plans" :key="i" :value="p.id">{{ p.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="status">Status</label>
                <select
                  class="form-select"
                  name="status"
                  v-model="filter.status"
                >
                  <option value="">Todos</option>
                  <option value="0">Cancelados</option>
                  <option value="1">Publicados</option>
                  <option value="2">Em Produção</option>
                  <option value="3">Encerrados</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>
import { errorMessage } from '@/components/alertsMessages'
import service from '@/pages/events/services'

export default {
  name: 'events-filter',
  components: {
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Eventos',
      filter: {},
      plans: [],
      modal: false
    }
  },
  methods: {
    getPlans () {
      service.listPlans({}).then(({ data }) => {
        this.plans = data.data
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
      })
    },
    open () {
      this.modal = true
      this.getPlans()
    },
    applyFilter () {
      this.$emit('filtered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {}
    }
  },
  computed: {
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
