import { routes as auth } from '../modules/auth'
import { routes as home } from '../pages/home'
import { routes as users } from '../pages/users'
import { routes as companies } from '../pages/companies'
import { routes as events } from '../pages/events'
import { routes as plans } from '../pages/plans'
import { routes as invoices } from '../pages/invoices'
import { routes as pushers } from '../pages/pushers'

export default [
  ...auth,
  ...home,
  ...users,
  ...companies,
  ...events,
  ...plans,
  ...invoices,
  ...pushers
]
