<template>
  <b-modal v-model="modal" :title="title" size="md" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveUser()" autocomplete="off">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="name">Nome completo</label>
                <input
                  id="name"
                  v-model="user.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.user.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.user.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="email">E-mail</label>
                <input
                  id="email"
                  v-model="user.email"
                  name="email"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.user.email.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.user.email.required"
                  class="invalid-feedback"
                >
                  O campo email é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="password">Senha</label>
                <span v-if="!user.id">
                  <input
                    id="password"
                    v-model="user.password"
                    name="password"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.user.password.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.user.password.required"
                    class="invalid-feedback"
                  >
                    O campo senha é obrigatório.
                  </div>
                </span>
                <span v-if="user.id">
                  <input
                    id="password"
                    v-model="user.password"
                    name="password"
                    type="text"
                    class="form-control"
                  />
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveUser()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    iduser: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Usuário',
      loading: true,
      selected: '',
      fullPage: true,
      submitted: false,
      user: {},
      modal: false
    }
  },
  validations () {
    if (this.user.id) {
      return {
        user: {
          name: { required },
          email: { required }
        }
      }
    } else {
      return {
        user: {
          name: { required },
          email: { required },
          password: { required }
        }
      }
    }
  },
  methods: {
    ...mapActions('users', {
      getUser: 'getUser',
      postData: 'save'
    }),
    loadItem (id) {
      if (id) {
        this.title = 'Editar Usuário'
        this.getUser(id).then((data) => {
          this.user = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveUser () {
      this.submitted = true
      if (this.selected) {
        this.user.role = this.selected.id
      }
      console.log(this.user)
      this.$v.user.$touch()
      if (this.$v.user.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.user).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.submitted = false
      this.modal = false
      this.user = {}
      this.selected = ''
      this.title = 'Novo Usuário'
      this.$v.$reset()
    }
  },
  computed: {
    ...mapState('users', { data: 'admin_user' })
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
