<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Dados da fatura</h4>
            <div class="row">
              <div class="col-sm-5">
                <div class="form-group mb-3">
                  <label for="name">
                    Título da Fatura
                  </label>
                  <input
                    id="name"
                    v-model="invoice.name"
                    name="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.invoice.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.invoice.name.required"
                    class="invalid-feedback"
                  >
                    O campo título é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group mb-3">
                  <label for="company">
                    Empresa
                  </label>
                  <multiselect
                    tag-placeholder="Selecione uma empresa"
                    placeholder="Selecione uma empresa"
                    v-model="invoice.company"
                    :options="optionsCompanies"
                    class="helo"
                    selectLabel="Click para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Click para remover"
                    label="name"
                    track-by="id"
                    :max-height="180"
                    open-direction="bottom"
                  >
                  </multiselect>
                  <div
                    v-if="submitted && !$v.invoice.company.required"
                    class="invalid-feedback"
                  >
                    O campo empresa é obrigatório
                  </div>
                </div>
              </div>

              <div class="col-sm-2">
                <div class="form-group mb-3">
                  <label for="invoice_date">
                    Data da Fatura
                  </label>
                  <date-picker
                    v-model="invoice.invoice_date"
                    type="date"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY"
                    :class="{
                      'is-invalid': submitted && $v.invoice.invoice_date.$error,
                    }"
                  >
                  </date-picker>
                  <div
                    v-if="submitted && !$v.invoice.invoice_date.required"
                    class="invalid-feedback"
                  >
                  O campo data fatura é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group mb-3">
                  <label for="invoice_due_date">
                    Data de Vencimento
                  </label>
                  <date-picker
                    v-model="invoice.invoice_due_date"
                    type="date"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY"
                    :class="{
                      'is-invalid': submitted && $v.invoice.invoice_due_date.$error,
                    }"
                  >
                  </date-picker>
                  <div
                    v-if="submitted && !$v.invoice.invoice_due_date.required"
                    class="invalid-feedback"
                  >
                  O campo data de vencimento é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <label for="obs">
                    Descrição da Fatura
                  </label>
                  <textarea
                    id="obs"
                    v-model="invoice.description"
                    name="obs"
                    type="text"
                    rows="4"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.invoice.invoice_due_date.$error,
                    }"
                  >
                  </textarea>
                  <div
                    v-if="submitted && !$v.invoice.description.required"
                    class="invalid-feedback"
                  >
                  O campo descrição é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4 float-sm-start">Eventos</h4>
            <div class="float-sm-end d-flex">
              <div class="text-sm-left me-2">
                <button type="button" class="btn btn-success btn-sm btn-rounded mb-2" @click="addEvent()">
                  <i class="mdi mdi-plus mr-1"></i> Adicionar Evento
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Evento</th>
                    <th>Capacidade</th>
                    <th>Período</th>
                    <th>Valor</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, i) in eventsNew" :key="i">
                    <td>
                      <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-dark': `${parseInt(list.status)}` === '0',
                        'badge-soft-success': `${parseInt(list.status)}` === '1',
                        'badge-soft-warning': `${parseInt(list.status)}` === '2',
                        'badge-soft-warning': `${parseInt(list.status)}` === '3',
                        'badge-soft-danger': `${parseInt(list.status)}` === '4',
                      }">{{ statusLabel[list.status] }}</span>
                    </td>
                    <td>{{ list.name }}</td>
                    <td>{{ list.plan.data.name}}</td>
                    <td>{{ $moment(list.start_date).format('DD/MM HH:mm') }} - {{ $moment(list.end_date).format('DD/MM HH:mm') }}</td>
                    <td class="text-sm-end">{{ list.event_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>

                    <td>
                      <b-dropdown class="card-drop" variant="white" right toggle-class="p-0" menu-class="dropdown-menu-end">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="delEvent(i)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> Remover
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="events.length > 0" class="mt-2">
                    <td colspan="4" class="border-0 text-sm-end">
                      <strong>Total Fatura</strong>
                    </td>
                    <td class="border-0 text-sm-end">
                      <h5 class="m-0">{{ totalEvents.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h5>
                    </td>
                    <td class="border-0"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <button type="buton" @click="cancelar()" class="btn btn-dark me-1">
                Cancelar
              </button>
              <button type="button" @click="saveInvoice()" class="btn btn-primary me-1">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" ref="formEvent" @created="addEventInvoice"></modal-form>
  </Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import Multiselect from 'vue-multiselect'
import ModalForm from './Form'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import service from '@/pages/companies/services'

export default {
  components: {
    Layout,
    Multiselect,
    ModalForm,
    DatePicker,
    PageHeader
  },
  name: 'form-invoice',
  page: {
    title: 'Nova Fatura',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      optionsCompanies: [],
      idEvent: '',
      modalForm: false,
      events: [],
      eventsNew: [],
      statusLabel: ['Cancelado', 'Publicado', 'Em Produção', 'Em Produção - Otimizando', 'Encerrado'],
      invoice: {
        id: '',
        name: '',
        status: 0
      },
      submitted: false,
      title: 'Criar Fatura',
      items: [{
        text: 'Faturas',
        href: '/invoices'
      },
      {
        text: 'Nova Fatura',
        active: true
      }
      ]
    }
  },
  validations: {
    invoice: {
      name: { required },
      description: { required },
      company: { required },
      invoice_date: { required },
      invoice_due_date: { required }
    }
  },
  mounted () {
    this.loadCompanies()
    this.loadItem()
  },
  computed: {
    ...mapState('invoices', { data: 'invoice' }),
    ...mapState('auth', ['user']),
    totalEvents () {
      return this.events.reduce(function (total, value) {
        return total + parseFloat(value.event_price)
      }, 0)
    }
  },
  methods: {
    ...mapActions('invoices', {
      fetch: 'getInvoice',
      postData: 'save'
    }),
    addEvent () {
      if (!this.invoice.company) {
        errorMessage('Alerta: Você precisa selecionar a empresa')
        return
      }
      this.$refs.formEvent.open('', this.invoice.company.id)
      this.modalForm = true
    },
    delEvent (idx) {
      this.eventsNew.splice(idx, 1)
    },
    addEventInvoice (data) {
      this.eventsNew = [...this.eventsNew, data]
    },
    loadCompanies () {
      service.list({ type: 1 }).then((res) => {
        this.optionsCompanies = res.data.data
      })
    },
    cancelar () {
      this.$router.push({ name: 'invoices' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Fatura'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.invoice = this.data
            this.invoice.invoice_date = this.$moment(this.data.invoice_date, 'YYYY-MM-DD HH:mm').toDate()
            this.invoice.invoice_due_date = this.$moment(this.data.invoice_due_date, 'YYYY-MM-DD HH:mm').toDate()
            if (this.data.company) {
              this.invoice.company = this.data.company.data
            }
            if (this.data.items) {
              console.log('chamou')
              this.events = this.data.items.data
              this.organizeItems()
            }
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    organizeItems () {
      if (this.events) {
        this.events.map((o, i) => {
          const item = {
            id: o.event_id,
            event_price: o.event_price,
            status: o.event.data.status,
            start_date: o.event.data.start_date,
            end_date: o.event.data.end_date,
            name: o.event.data.name,
            plan: o.event.data.plan
          }

          this.eventsNew.push(item)
        })
      }
    },
    async saveInvoice () {
      this.submitted = true
      this.$v.invoice.$touch()
      if (this.$v.invoice.$invalid) {
        window.scroll(0, 0)
        return
      }

      let loader = this.$loading.show()
      this.invoice.events = this.eventsNew
      await this.postData(this.invoice).then((data) => {
        let message = 'Sucesso: Ação registrada.'
        let delay = 2500
        successMessage(message, delay).then(() => {
          loader.hide()
          this.cancelar()
        })
      }).catch((err) => {
        console.log(err)
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        loader.hide()
      })
    }
  },
  watch: {
  }
}
</script>
