import http from '@/http'
const URL = '/admin/invoices'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=company,items.event.plan,items.event.operator`))
  }
  return one
}

export const save = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}

export const changeStatus = (payload) => http.put(URL + `/${payload.id}/status`, payload)

export default {
  save,
  list,
  show,
  changeStatus
}
