import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_COMPANIES, res.data)
  })
)

export const getCompany = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_COMPANY, res.data.data)
  })
}
export const save = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_COMPANY, res.data.data)
    return res
  })
}
export const savePassword = async ({ commit }, obj) => {
  await services.newPassword(obj).then(res => {
    return res
  })
}

export const changeStatus = async ({ commit }, obj) => {
  await services.changeStatus(obj).then(res => {
    return res
  })
}
