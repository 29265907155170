<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <h4 class="float-end font-size-16">Fatura # {{ invoice.id }}</h4>
              <div class="mb-4">
                <img src="@/assets/images/logo-eventfy.jpg" alt height="44" />
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <address>
                  <strong>Faturado para:</strong>
                  <br />{{ invoice.company.fiscal_name }} ({{ invoice.company.name }})<br />{{ invoice.company.cnpj }} <br />{{ invoice.company.phone }}
                  <br />{{ invoice.company.email }}
                </address>
              </div>
              <div class="col-6 text-sm-end">
                <address>
                  <strong>Detalhes da Fatura:</strong>
                  <br />{{ invoice.description }} <br /><strong>Faturado:</strong> {{ $moment(invoice.invoice_date).format('DD/MM/YYYY') }}<br /><strong>Vencimento:</strong> {{ $moment(invoice.invoice_due_date).format('DD/MM/YYYY') }}
                  <br /><span class="badge badge-pill font-size-12" :class="{
                      'badge-soft-warning': `${parseInt(invoice.status)}` === '1',
                      'badge-soft-success': `${parseInt(invoice.status)}` === '2',
                      'badge-soft-dark': `${parseInt(invoice.status)}` === '3',
                    }">{{ statusLabel[invoice.status] }}</span>
                </address>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-6 mt-3">
                <address>
                  <strong>Payment Method:</strong>
                  <br />Visa ending **** 4242 <br />jsmith@email.com
                </address>
              </div>
              <div class="col-6 mt-3 text-sm-end">
                <address>
                  <strong>Order Date:</strong>
                  <br />October 16, 2019
                  <br />
                  <br />
                </address>
              </div>
            </div> -->
            <div class="p-2 mt-3">
              <h3 class="font-size-16">Eventos atendidos</h3>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 50px">N.</th>
                    <th width="40%">Descrição</th>
                    <th width="30%">Período</th>
                    <th width="20%">Plano</th>
                    <th width="10%" class="text-sm-end">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(event, i) in invoice.events" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>{{ event.event.data.name }}</td>
                    <td>{{ $moment(event.event.data.start_date).format('DD/MM/YY HH:mm') }} - {{ $moment(event.event.data.end_date).format('DD/MM/YY HH:mm') }}</td>
                    <td>{{ event.event.data.plan.data.name }}</td>
                    <td class="text-sm-end">{{ event.event_price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
                  </tr>
                  <!-- <tr>
                    <td colspan="4" class="border-0 text-sm-end">
                      <strong>Shipping</strong>
                    </td>
                    <td class="border-0 text-sm-end">$13.00</td>
                  </tr> -->
                  <tr>
                    <td colspan="4" class="border-0 text-sm-end">
                      <strong>Total Fatura</strong>
                    </td>
                    <td class="border-0 text-sm-end">
                      <h4 class="m-0">{{ invoice_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-print-none">
              <div class="float-start">
                <a
                  href="javascript:void(0)"
                  @click="cancelar()"
                  class="btn btn-dark waves-effect waves-light me-1"
                  >Voltar</a>
              </div>
              <div class="float-end">
                <a
                  href="javascript:window.print()"
                  class="btn btn-success waves-effect waves-light me-1"
                  ><i class="fa fa-print"></i
                > Imprimir Fatura</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'show-invoice',
  page: {
    title: 'Detalhes da fatura',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      plans: [],
      statusLabel: ['', 'Pagamento Pendente', 'Pago', 'Cancelada'],
      invoice: {
        id: '',
        name: '',
        total_invoice: 0,
        status: 0,
        company: {}
      },
      submitted: false,
      title: 'Detalhes da Fatura',
      items: [{
        text: 'Faturas',
        href: '/invoices'
      },
      {
        text: 'Detalhes da Fatura',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.loadItem()
  },
  computed: {
    ...mapState('invoices', { data: 'invoice' }),
    ...mapState('auth', ['user']),
    invoice_total () {
      return this.invoice.invoice_total || 0
    }
  },
  methods: {
    ...mapActions('invoices', {
      fetch: 'getInvoice',
      postData: 'save'
    }),
    showPassword (idx) {
      this.event.user[idx].showPass = !this.event.user[idx].showPass
    },
    cancelar () {
      this.$router.push({ name: 'invoices' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Evento'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.invoice = this.data
            if (this.data.items) {
              this.invoice.events = this.data.items.data
            }
            if (this.data.company) {
              this.invoice.company = this.data.company.data
            }
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    }
  },
  watch: {}
}
</script>

<style scoped>
  .domain-eventfy {
    padding: 20px;
    gap: 1rem;
    text-align: center;
    padding-top: 15%;
    max-height: 35rem;
    height: 23rem;
    background: #FFFFFF;
    border: 1px solid #45506F;
    box-sizing: border-box;
    box-shadow: 0px 3px 8px 4px rgba(0, 0, 0, 0.01);
    border-radius: 7px;
    margin: 2rem;
    cursor: pointer;
  }
  .domain-eventfy:hover {
    background: #eaefff;
  }
  .active {
    background: #eaefff;
  }
</style>
<style type="text/css" media="print">
  @page { size: landscape; }
</style>
