<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Bem vindo de volta!</h5>
                  <p>Faça login para continuar no Eventfy.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
            </div>
            <b-alert
              v-model="isAuthError"
              variant="danger"
              class="mt-3"
              dismissible
              >{{ authError }}</b-alert
            >

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <b-form-group
                class="mb-3"
                id="input-group-1"
                label="Email"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="text"
                  placeholder="Informe email"
                  :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                ></b-form-input>
                <div
                  v-if="submitted && $v.form.email.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.email.required">E-mail é obrigatório.</span>
                  <span v-if="!$v.form.email.email">Por favor, informe e-mail válido.</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="input-group-2"
                label="Senha"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.password"
                  type="password"
                  placeholder="Informe sua senha"
                  :class="{ 'is-invalid': submitted && $v.form.password.$error }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.password.required"
                  class="invalid-feedback"
                >
                  Senha é obrigatória.
                </div>
              </b-form-group>
              <div class="mt-4 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                  >Log In</b-button
                >
              </div>
              <!-- <div class="mt-4 text-center">
                <router-link tag="a" to="/forgot-password" class="text-muted">
                  <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                </router-link>
              </div> -->
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            © {{ new Date().getFullYear() }} Eventfy. Feito com
            <i class="mdi mdi-heart text-danger"></i> por XCODE Tecnologia
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
<script>
import Layout from '@/components/layout/auth'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import appConfig from '@/app.config'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  page: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout
  },
  data () {
    return {
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  methods: {
    ...mapActions('auth', ['ActionDoLogin']),
    async tryToLogIn () {
      try {
        this.submitted = true
        // stop here if form is invalid
        this.$v.form.$touch()

        if (this.$v.form.$invalid) {
          return
        }
        await this.ActionDoLogin(this.form)
        let message = 'Sucesso: Login efetuado com sucesso.'
        let delay = 3000
        successMessage(message, delay).then(() => {
          this.loading = false
          this.$router.push({ name: 'home' })
        })
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: Ops, o usuário e/ou senha informados são inválidos. Verifique os parâmetros informados e tente novamente.')
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    width: 30%;
  }
}
</style>
