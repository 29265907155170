<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Dados da empresa</h4>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group mb-3">
                  <label for="fiscal_name">
                    Razão Social
                  </label>
                  <input
                    id="fiscal_name"
                    v-model="company.fiscal_name"
                    name="fiscal_name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.fiscal_name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.fiscal_name.required"
                    class="invalid-feedback"
                  >
                    O campo razão social é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group mb-3">
                  <label for="name">
                    Nome Fantasia
                  </label>
                  <input
                    id="name"
                    v-model="company.name"
                    name="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.name.required"
                    class="invalid-feedback"
                  >
                    O campo nome fantasia é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group mb-3">
                  <label for="cnpj">
                    CNPJ
                  </label>
                  <input
                    id="cnpj"
                    v-model="company.cnpj"
                    name="cnpj"
                    type="text"
                    v-mask="'##.###.###/####-##'"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.cnpj.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.cnpj.required"
                    class="invalid-feedback"
                  >
                  O campo CNPJ é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group mb-3">
                  <label for="phone">
                    Telefone
                  </label>
                  <input
                    id="phone"
                    v-model="company.phone"
                    name="phone"
                    v-mask="'(##) #####-####'"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.phone.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.phone.required"
                    class="invalid-feedback"
                  >
                  O campo telefone é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group mb-3">
                  <label for="email">
                    E-mail Contato
                  </label>
                  <input
                    id="email"
                    v-model="company.email"
                    name="email"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.email.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.email.required"
                    class="invalid-feedback"
                  >
                  O campo e-mail de contato é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mb-3">
                  <label for="obs">
                    Observações
                  </label>
                  <textarea
                    id="obs"
                    v-model="company.obs"
                    name="obs"
                    type="text"
                    rows="4"
                    class="form-control"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Dados do administrador</h4>
            <div class="row">
              <div class="col-sm-5">
                <div class="form-group mb-3">
                  <label for="username">
                    Nome do usuário
                  </label>
                  <input
                    id="username"
                    v-model="company.user.name"
                    name="username"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.user.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.user.name.required"
                    class="invalid-feedback"
                  >
                    O campo nome é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="useremail">
                    E-mail
                  </label>
                  <input
                    id="useremail"
                    v-model="company.user.email"
                    name="useremail"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.user.email.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.user.email.required"
                    class="invalid-feedback"
                  >
                    O campo e-mail é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="senha">
                    Senha
                  </label>
                  <input
                    id="senha"
                    v-model="company.user.password"
                    name="senha"
                    type="password"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.company.user.password.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.company.user.password.required"
                    class="invalid-feedback"
                  >
                    O campo senha é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Dados do domínio</h4>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group mb-3">
                  <label for="domain_id">
                    Escolha um domínio
                  </label>
                  <select
                    class="form-select"
                    name="domain_id"
                    id="domain_id"
                    v-model="company.domain_id"
                    :class="{
                      'is-invalid': submitted && $v.company.domain_id.$error,
                    }">
                    <option v-for="(domain, i) in domains" :key="i" :value="domain.id">{{ domain.domain }}</option>
                  </select>
                  <div
                    v-if="submitted && !$v.company.domain_id.required"
                    class="invalid-feedback"
                  >
                  O campo é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <button type="buton" @click="cancelar()" class="btn btn-dark me-1">
                Cancelar
              </button>
              <button type="button" @click="saveCompany()" class="btn btn-primary me-1">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import service from './services'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'form-company',
  page: {
    title: 'Nova Empresa',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      domains: [],
      company: {
        id: '',
        name: '',
        status: 0,
        user: {
          password: '',
          name: '',
          email: ''
        }
      },
      submitted: false,
      title: 'Criar Empresa',
      items: [{
        text: 'Empresas',
        href: '/companies'
      },
      {
        text: 'Nova Empresa',
        active: true
      }
      ]
    }
  },
  validations: {
    company: {
      name: { required },
      fiscal_name: { required },
      phone: { required },
      cnpj: { required },
      email: { required },
      domain_id: { required },
      user: {
        name: { required },
        email: { required },
        password: { required }
      }
    }
  },
  mounted () {
    this.loadItem()
    this.getDomains()
  },
  computed: {
    ...mapState('companies', { data: 'company' }),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('companies', {
      fetch: 'getCompany',
      postData: 'save'
    }),
    getDomains () {
      service.listDomains({}).then(({ data }) => {
        this.domains = data.data
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
      })
    },
    cancelar () {
      this.$router.push({ name: 'companies' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Empresa'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.company = this.data
            if (this.data.users) {
              this.company.user = this.data.users.data
            }
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    async saveCompany () {
      this.submitted = true
      this.$v.company.$touch()
      if (this.$v.company.$invalid) {
        window.scroll(0, 0)
        return
      }

      let loader = this.$loading.show()

      await this.postData(this.company).then((data) => {
        let message = 'Sucesso: Ação registrada.'
        let delay = 2500
        successMessage(message, delay).then(() => {
          loader.hide()
        })
      }).catch((err) => {
        console.log(err)
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        loader.hide()
      })
    }
  },
  watch: {
  }
}
</script>
