<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <div class="search-box mr-2 mb-2 d-inline-block ">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Digite e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded mb-2 mr-2" @click="newPusher()">
                      <i class="mdi mdi-plus mr-1"></i> Adicionar
                    </button>
                  </div>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th>Pusher ID</th>
                    <th>Pusher Key</th>
                    <th>Cluster</th>
                    <th>Disponível</th>
                    <th>Evento</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in pushers" :key="list.id">
                    <td>{{ list.pusher_id }}</td>
                    <td>{{ list.pusher_key }}</td>
                    <td>{{ list.pusher_cluster }}</td>
                    <td>
                      <span class="badge badge-pill font-size-12" :class="{
                        'badge-soft-success': `${parseInt(list.available)}` === '1',
                        'badge-soft-danger': `${parseInt(list.available)}` === '0',
                      }">{{ list.available ? 'SIM' : 'NÃO' }}</span>
                    </td>
                    <td>{{ list.event_id ? list.event.data.name : '' }}</td>
                    <td>
                      <b-dropdown class="card-drop" variant="white" right toggle-class="p-0" menu-class="dropdown-menu-end">
                        <template v-slot:button-content>
                          <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>
                        <b-dropdown-item @click="editPusher(list.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleStatus(list.id)">
                          <i class="fas fa-circle mr-1" :class="{ 'text-danger': parseInt(list.available) === 1, 'text-success': parseInt(list.available) === 0 }"></i> {{ list.available ? 'Alterar p/ Indisponível' : 'Alterar p/ Disponível' }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-form v-model="modalForm" :idPusher="idPusher" ref="formPusher" @created="updateList"></modal-form>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import ModalForm from './Form'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    ModalForm
  },
  name: 'pushers',
  page: {
    title: 'Pushers',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      search: '',
      timeout: null,
      modalForm: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idPusher: null,
      perPage: 20,
      submitted: false,
      title: 'Pushers',
      items: [{
        text: 'Pushers'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('pushers', ['pushers', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('pushers', {
      getAll: 'getAll',
      changeStatus: 'changeStatus',
      deleteFile: 'deletePlan'
    }),
    showFilters () {
      this.$refs.modalFilter.open()
    },
    newPusher () {
      this.$refs.formPusher.open('')
      this.modalForm = true
    },
    editPusher (id) {
      this.$refs.formPusher.open(id)
      this.modalForm = true
      this.idPusher = id
    },
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    getReport (filter) {
      let params = {
        include: 'event',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.table-responsive { min-height: 500px;}
.float-sm-end { float: right }
</style>
