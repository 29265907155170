export const menuItems = [
  {
    id: 1,
    label: 'menuitems.menu.text',
    isTitle: true
  },
  {
    id: 2,
    label: 'menuitems.dashboards.text',
    icon: 'bx-home-circle',
    link: '/events'
  },
  {
    id: 3,
    label: 'menuitems.apps.text',
    isTitle: true
  },
  {
    id: 8,
    label: 'menuitems.pushers.text',
    icon: 'dripicons-jewel',
    link: '/pushers'
  },
  {
    id: 4,
    label: 'menuitems.company.text',
    icon: 'mdi mdi-bank',
    link: '/companies'
  },
  {
    id: 6,
    label: 'menuitems.plans.text',
    icon: 'dripicons-jewel',
    link: '/plans'
  },
  {
    id: 5,
    label: 'menuitems.myevent.text',
    icon: 'bx-laptop',
    link: '/events'
  },
  {
    id: 7,
    label: 'menuitems.invoices.text',
    icon: 'dripicons-document',
    link: '/invoices'
  }
]
