import * as types from './mutation-types'
let defaultObject = () => ({
  token: ''
})

export default {
  [types.SET_INVOICE] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.invoice = payload
  },
  [types.SET_INVOICES] (state, payload) {
    state.invoices = payload.data
    state.pagination = payload.meta.pagination
  }
}
