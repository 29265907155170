export default [
  {
    name: 'pushers',
    path: '/pushers',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Pushers',
      icon: 'dvr',
      label: 'Pushers'
    }
  }
]
