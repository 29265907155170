export default [
  {
    name: 'plans',
    path: '/plans',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Planos',
      icon: 'dvr',
      label: 'Planos'
    }
  }
]
