import * as types from './mutation-types'
let defaultObject = () => ({
  token: ''
})

export default {
  [types.SET_COMPANY] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.company = payload
  },
  [types.SET_COMPANIES] (state, payload) {
    state.companies = payload.data
    state.pagination = payload.meta.pagination
  }
}
